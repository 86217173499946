<template>
  <div class="container-fluid">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-3">
            <div class="form-group">
              <label>{{ i18n("Menu.MenuName") }}</label>
              <treeselect
                :options="menuOptions"
                :disable-branch-nodes="true"
                placeholder="請選擇"
                v-model="search.menuGuid"
              />
            </div>
          </div>
          <div class="col-sm-3 col-md-3">
            <div class="form-group">
              <label>{{ i18n("Features.FeatureName") }}</label>
              <input
                class="form-control"
                type="text"
                placeholder="請輸入關鍵字"
                @keydown.stop.prevent.enter="getPermission"
                v-model.trim="search.name"
              />
            </div>
          </div>
          <div class="col-sm-3 col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-search"
              style="margin-top: 30px; margin-left: 10px"
              @click="getPermission"
            >
              {{ i18n("Buttons.Search") }}
            </button>
            <button
              type="button"
              style="margin-top: 30px; margin-left: 10px"
              class="btn btn-primary btn-create"
              data-toggle="modal"
              data-target="#featureModal"
              @click="openModal(true)"
            >
              {{ i18n("Buttons.AddFeature") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="yahome-table-wrap">
      <VueBootstrap4Table :rows="rows" :columns="columns" :config="config">
        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-info btn-edit btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#featureModal"
            @click="openModal(false, props.row)"
          >
            {{ i18n("Buttons.Edit") }}
          </button>
        </template>

        <template slot="delete" slot-scope="props">
          <button
            class="btn btn-danger btn-delete btn-sm"
            data-toggle="modal"
            data-target="#delFeatureModal"
            @click="openModal(false, props.row)"
          >
            {{ i18n("Buttons.Delete") }}
          </button>
        </template>
        <template slot="empty-results"> 暫無資料 </template>
      </VueBootstrap4Table>
    </div>

    <!-- 新增&編輯功能 Modal -->
    <div
      id="featureModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="permisson"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @submit.prevent="saveData" @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="isNew">{{ i18n("Features.AddFeature") }}</span>
              <span v-else>{{ i18n("Features.EditFeature") }}</span>
            </div>
            <div class="modal-body" style="height: 400px">
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  :name="i18n('Menu.MenuName')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>
                    {{ i18n("Menu.MenuName") }}
                  </label>
                  <treeselect
                    :options="menuOptions"
                    :disable-branch-nodes="true"
                    placeholder="請選擇"
                    v-model="permisson.MenuGuid"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  :name="i18n('Features.FeatureName')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>
                    {{ i18n("Features.FeatureName") }}
                    <span class="mark">(不可重複)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model.trim="permisson.Name"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  :name="i18n('Features.Type')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>
                    {{ i18n("Features.Type") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model.trim="permisson.ClaimType"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="section"
                  :name="i18n('Features.DeclareValue')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>
                    {{ i18n("Features.DeclareValue") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model.trim="permisson.ClaimValue"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(saveData)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- 刪除功能 Modal -->
    <div
      id="delFeatureModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Features.DeleteFeature") }}</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ permisson.Name }}
            </span>
            ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="delItem"
            >
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters } from "vuex";

export default {
  name: "Features",

  data() {
    return {
      is_composing: false, // compositionstart& compositionend

      // 搜尋
      search: {
        name: null,
        menuGuid: null,
      },

      value: null,
      isNew: false,

      // 功能
      permisson: {},

      // 選單
      menuOptions: [],

      rows: [],
      columns: [
        {
          label: this.i18n("Menu.MenuName"),
          name: "MenuName"
        },
        {
          label: this.i18n("Features.FeatureName"),
          name: "Name"
        },
        {
          label: this.i18n("Features.Type"),
          name: "ClaimType"
        },
        {
          label: this.i18n("Features.DeclareValue"),
          name: "ClaimValue"
        },
        {
          label: "",
          name: "edit",
          slot_name: "edit"
        },
        {
          label: "",
          name: "delete",
          slot_name: "delete"
        },
      ],
      config
    };
  },

  computed: {
    ...mapGetters(["lang"]),
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    lang: function() {
      this.columns = [
        { label: this.i18n("Menu.MenuName"), name: "MenuName" },
        { label: this.i18n("Features.FeatureName"), name: "Name" },
        { label: this.i18n("Features.Type"), name: "ClaimType" },
        { label: this.i18n("Features.DeclareValue"), name: "ClaimValue" },
        { label: "", name: "edit", slot_name: "edit" },
        { label: "", name: "delete", slot_name: "delete" },
      ]
    },
  },

  methods: {
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n (keyStr) {
      return this.$t(keyStr);
    },
    // 取得選單 API
    getMenu() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            let menuList = [];
            response.data.forEach((item) => {
              let children = [];
              item.Children.forEach((item) => {
                let data = {
                  id: item.Guid,
                  label: item.MenuName,
                };
                children.push(data);
              });
              let parent = {
                id: item.Guid,
                label: item.MenuName,
                children: children,
              };
              menuList.push(parent);
            });
            vm.menuOptions = menuList;
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 取得功能 API
    getPermission() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/permission`;

      if (!this.search.menuGuid) {
        this.search.menuGuid = null;
      }

      const params = {
        name: vm.search.name,
        menuGuid: vm.search.menuGuid,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api, { params })
        .then((response) => {
          if (response.status === 200) {
            vm.rows = response.data;
            vm.$store.dispatch("updateLoading", false);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 新增 編輯 刪除 功能 Modal
    openModal(isNew, item) {
      if (!isNew) {
        this.permisson = { ...item };
        this.isNew = false;
      } else {
        this.permisson = {};
        this.isNew = true;
      }
      this.$refs.permisson.reset();
    },

    // 新增&編輯功能 API
    saveData() {
      const vm = this;

      const api = `${window.BaseUrl.api}/auth/permission`;
      let httpMethod = "post";
      let message = "新增成功";

      if (!vm.isNew) {
        httpMethod = "put";
        message = "編輯成功";
      }

      const data = {
        MenuGuid: vm.permisson.MenuGuid,
        Name: vm.permisson.Name,
        ClaimType: vm.permisson.ClaimType,
        ClaimValue: vm.permisson.ClaimValue,
        Guid: vm.permisson.Guid,
      };

      $("#featureModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http[httpMethod](api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: message,
              type: "success",
              duration: 2000,
            });
            vm.getPermission();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 刪除功能 API
    delItem() {
      const vm = this;
      let api = `${window.BaseUrl.api}/auth/permission/${vm.permisson.Guid}`;

      $("#featureModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "刪除成功",
              type: "success",
              duration: 2000,
            });
            vm.getPermission();
          } else {
            vm.$store.dispatch("updateLoading", false);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
  },

  created() {
    this.getPermission();
    this.getMenu();
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>
